import React from "react"
import glaceHero from '../images/glaceHero.jpg';
import Layout from "../components/layout"
import Product from "../components/product/product"
import SEO from "../components/seo"
import bacg from "../images/products/ice/bac.jpg";
import barg from "../images/products/ice/barre.jpg";
import mag from "../images/products/ice/magnum.jpg";
import vac from "../images/products/pastry/vacherin.jpg";
import fru from "../images/products/pastry/fruitiere.jpg";

const products =[
  {
    name:"Bâtonnet glacé",
    description : "Bâtonnet à la glace vanille enrobé de chocolat noir 66 % ou chocolat au lait.",
    img : mag
  },
  {
    name:"Bac de glace ou sorbet",
    description : "Parfum au choix :  yaourt, fleur de lait, coco, vanille, pistache, caramel fleur de sel, café, praliné, chocolat, citron, melon, banane, mangue, passion, fraise ou framboise. (Possibilité en 500ml et 1 L).",
    img : bacg
  },
  {
    name:"Barre glacée",
    description : "Croustillant au riz soufflé et chocolat noir, mousse glacée au chocolat noir 55 %, caramel, enrobé de chocolat noir 55 %.",
    img : barg
  }, 
  {
    name:"Vacherin",
    description : "Meringue, 3 parfums au choix et crème chantilly.",
    img : vac
  },
  {
    name:"Fruitière",
    description : "Assortiments de boules de sorbets, glace à la vanille et génoise.",
    img : fru
  }, 
]

const IndexPage = () => (
  <Layout>
    <SEO title="Glaces" />
    <div className="products">
      <section>
        <div className="fluid-container">
          <div className="row">
            <img src={glaceHero} alt="Glaces" style={{width:"100%"}}/>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <h1 className="col-md-12 times" style={{textAlign:"center",margin:"62px 0px"}}>Nos glaces</h1>
          </div>
          <div className="row">
            <div className="col-md-12" style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
            }}>
            {
              products.map((product)=><Product name={product.name} description={product.description} img={product.img} />)
            }
            </div>
          </div>
        </div>
      </section>

    </div>
  </Layout>
)

export default IndexPage
